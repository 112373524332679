import { useUserStore } from '@/stores/user.js'

export default async function auth({ next }) {
  try {
    const userStore = useUserStore()
    if (!userStore.user) {
      return next({ name: 'login' })
    } else if (userStore.user.banned === 1) {
      return next({ name: 'login' })
    } else if (userStore.user.email_verified === 0) {
      return next({ name: 'signup-confirm' })
    }
    return next()
  } catch (e) {
    return next({ name: 'login' })
  }
}
