import { useUserStore } from '@/stores/user.js'

export default function userLevel(userLevel = 0) {
  return async function ({ next }) {
    try {
      const userStore = useUserStore()
      if (parseInt(userStore.user?.user_level || '0') < userLevel) {
        return next({ name: 'dashboard' })
      }
      return next()
    } catch (e) {
      return next({ name: 'login' })
    }
  }
}
