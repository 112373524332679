import { acceptHMRUpdate, defineStore } from 'pinia'
import type { VendorType } from '@/types/vendor.type.js'
import CustomError from '@/js/custom-error.js'
import { Bugsnag } from '@/main.js'
import { Event } from '@bugsnag/js'

interface State {
  vendors: VendorType[]
  vendor: VendorType | null
}

export const useVendorsStore = defineStore('vendors', {
  state: (): State => {
    return {
      vendors: [],
      vendor: null
    }
  },
  getters: {
    owedVendors(state): VendorType[] {
      return state.vendors.filter((v) => v.invoices?.some((invoice) => invoice.balance > 0))
    }
  },
  actions: {
    async getVendors() {
      this.vendors = await $.get('/vendors')
    },
    async getVendorsInvoiceHistory(retailerId: string, context: string) {
      try {
        // refresh the list of vendors
        await this.getVendors()

        // load all of the invoice history for the retailer
        const addInvoicesPromises: any[] = []
        this.vendors.forEach((vendor) => {
          addInvoicesPromises.push(
            $.post('/vip/order-request-service/retailer/add-invoices', {
              account_code: vendor.vip_account_code,
              source_code: vendor.vip_distributor_source_code
            })
          )
        })
        await Promise.all(addInvoicesPromises)
      } catch (addInvoicesError: JQuery.jqXHR) {
        Bugsnag.notify(
          new CustomError(
            'POST /vip/order-request-service/retailer/add-invoices',
            new Error(addInvoicesError?.responseJSON?.error)
          ),
          function (event: Event) {
            event.severity = 'info'
            event.context = context
            event.addMetadata('request', {
              retailerId,
              body: addInvoicesError?.responseJSON,
              status: addInvoicesError?.status,
              method: 'POST'
            })
          }
        )
      }
    },
    async getVendor(id: number) {
      this.vendor = await $.get(`/vendors?id=${id}`)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVendorsStore, import.meta.hot))
}
