import { useUserStore } from '@/stores/user.js'
import type { UserPermissionsEnum } from '@/enums/user-permissions.enum'

export default function permission(type: UserPermissionsEnum) {
  return async function ({ next }) {
    try {
      const userStore = useUserStore()
      const { permissions } = userStore.user
      if (type in permissions && permissions[type] === 1) {
        return next()
      }
      return next({ name: 'dashboard' })
    } catch (e) {
      return next({ name: 'login' })
    }
  }
}
