<template>
  <div id="wrapper">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  head() {
    return {
      title: this.$route?.meta?.title || 'Backbar'
    }
  },
  created() {
    document.body.classList.add('gray-bg')
  }
})
</script>
