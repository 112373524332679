<template>
  <swiper
    slidesPerView="auto"
    spaceBetween="8px"
    :modules="modules"
    class="vendorSwiper"
    :navigation="true"
    :init="false"
    :allow-touch-move="false"
  >
    <swiper-slide class="option-card">
      <FilterCardComponent
        :isSelected="Object.keys(vendorFilter).length === 0"
        @click="onAllItemsClick()"
        cardName="All items"
      />
    </swiper-slide>
    <template v-for="(vendor, key) in vendors" :key="key">
      <swiper-slide class="vendor-card" :title="vendor.name">
        <label
          v-if="vendor.otc_enabled && vendorFilter[vendor.id]"
          title="This vendor regularly updates their product costs and stock. You'll get real time updates on orders placed with this vendor."
          data-placement="bottom"
          data-toggle="tooltip"
          class="filter-card-label"
        >
          <FilterCardComponent
            :icon="Icons.Store"
            :isSelected="vendorFilter[vendor.id]"
            :showOtcIcon="vendor.otc_enabled"
            @click="onCardClick(vendor)"
            :cardName="vendor.name"
          />
        </label>
        <FilterCardComponent
          v-else
          :icon="Icons.Store"
          :isSelected="vendorFilter[vendor.id]"
          :showOtcIcon="vendor.otc_enabled"
          @click="onCardClick(vendor)"
          :cardName="vendor.name"
          :imageUrl="vendor.logo_url"
        />
      </swiper-slide>
    </template>
  </swiper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Scrollbar, A11y } from 'swiper/modules'
import FilterCardComponent from './cards/button/FilterCardComponent.vue'
import CornerTag from './cards/nested/CornerTag.vue'
import TagsComponent from './TagsComponent.vue'
import { Icons, TagStyle, NestedCardStyle } from '@/enums/components.enum'
import { type VendorType } from '@/types/vendor.type'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'

export default defineComponent({
  name: 'Vendor Carousel Component',
  components: {
    FilterCardComponent,
    CornerTag,
    TagsComponent,
    Swiper, // disable Swiper unless on mobile
    SwiperSlide
  },
  data() {
    return {
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  setup() {
    return {
      Icons,
      NestedCardStyle,
      TagStyle,
      modules: [Navigation, Scrollbar, A11y]
    }
  },
  props: {
    vendors: {
      type: Array as PropType<VendorType[]>,
      default: null
    },
    vendorFilter: {
      type: Object,
      default: null
    },
    onCardClick: {
      type: Function,
      default: null
    },
    onAllItemsClick: {
      type: Function,
      default: null
    }
  }
})
</script>

<style>
.swiper {
  width: 100%;
  display: flex;
}

.swiper-wrapper {
  display: flex;
  align-items: stretch;
  height: 62px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  cursor: grab;
  cursor: pointer;
  height: 56px;
}

.swiper-slide.option-card {
  width: 56px;
}

.swiper-slide.vendor-card {
  width: 128px;
}

.swiper-button-prev,
.swiper-button-next {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 2px 4px 0px rgba(219, 223, 223, 0.75);
  background-color: #ffffff;
  margin-top: -10px;
  transition:
    background-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #f4f5f5; /* action-secondary hovered */
  transition:
    background-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.swiper-button-prev:active,
.swiper-button-next:active {
  background-color: #e8eaea; /* action-secondary pressed */
  transition:
    background-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.swiper-button-next::after {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Font Awesome 6 Free';
  content: '\f054';
  color: #313635; /* icon-default */
}

.swiper-button-prev::after {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Font Awesome 6 Free';
  content: '\f053';
  color: #313635; /* icon-default */
}

.swiper-button-disabled {
  background-color: #e6e8e8; /* action-secondary disabled */
  opacity: 1;
}

.swiper-button-disabled::after {
  color: #abb2b2; /* text disabled */
}

.filter-card-label {
  display: flex;
}
</style>
