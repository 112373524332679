import $ from 'jquery'

$.ajaxPrefilter((options, originalOptions) => {
  if (!options.crossDomain) {
    if (!['get', 'delete'].includes(options.type.toLowerCase()) && options.processData) {
      options.data = JSON.stringify(originalOptions.data)
      options.contentType = 'application/json'
    }
    options.url = `/api/v2${options.url}`
  }
})
