import { acceptHMRUpdate, defineStore } from 'pinia'
import type {
  ItemType,
  ShoppingCartItemType,
  ShoppingCartItemWithPricesType,
  VendorCart,
  VendorsWithShoppingCartItems
} from '@/types/item.type'
import type { ShoppingCartItemModelType } from '@/types/shopping-cart-item.type.js'
import { getCaseMultiplier } from '@/views/orders/cartUtils'

// vendorCartCost is a key-value pair of vendor ID and the total cost of items in the vendors cart
// vendorCarts is a key-value pair of vendorID and the items in a vendors cart

const getDefaultState = (): State => ({
  shoppingCartItems: [],
  shoppingCartItemsByItemName: [],
  shoppingCartItemsByAddedDate: [],
  shoppingCart: [],
  shoppingCartCost: {},
  vendorCarts: [],
  vendorCartCost: {},
  savedForLater: []
})

interface State {
  shoppingCartItems: ShoppingCartItemModelType[]
  shoppingCartItemsByItemName: ShoppingCartItemType[]
  shoppingCartItemsByAddedDate: ShoppingCartItemType[]
  shoppingCart: VendorsWithShoppingCartItems
  shoppingCartCost: Record<number, number>
  vendorCarts: VendorsWithShoppingCartItems
  vendorCartCost: Record<number, number>
  savedForLater: ShoppingCartItemWithPricesType[]
}

export const useShoppingCartStore = defineStore('shoppingCart', {
  state: (): State => {
    return getDefaultState()
  },
  actions: {
    async getShoppingCartItems(items: ItemType[]) {
      try {
        const shoppingCartItems: ShoppingCartItemModelType[] = await $.get('/shopping-cart-items')
        const cartItemsMap = new Map(shoppingCartItems.map((item) => [item.item_id, item]))

        const matchedShoppingCartItems: ShoppingCartItemType[] = items.map((item) => {
          const matchingShoppingCartItem = cartItemsMap.get(item.id)
          return matchingShoppingCartItem
            ? {
                ...item,
                shoppingCartItemId: matchingShoppingCartItem.id,
                shoppingCartItemCreateDate: matchingShoppingCartItem.create_date,
                save_for_later: matchingShoppingCartItem.save_for_later ?? false,
                select_vendor: matchingShoppingCartItem.select_vendor ?? '',
                unit_or_case: matchingShoppingCartItem.unit_or_case ?? 'unit',
                order_quantity: matchingShoppingCartItem.order_quantity ?? 1
              }
            : item
        })
        this.shoppingCartItems = shoppingCartItems
        this.shoppingCartItemsByItemName = matchedShoppingCartItems
      } catch (error) {
        console.error('Failed to fetch shopping cart items:', error)
      }
    },
    getShoppingCartItemsByAddedDate(items: ItemType[]) {
      try {
        const itemsMap = new Map(items.map((item) => [item.id, item]))

        const matchedShoppingCartItems: ShoppingCartItemType[] = this.shoppingCartItems
          .map((shoppingCartItem) => {
            const matchingItem = itemsMap.get(shoppingCartItem.item_id)
            if (matchingItem) {
              return {
                ...matchingItem,
                id: matchingItem.id,
                shoppingCartItemId: shoppingCartItem.id,
                shoppingCartItemCreateDate: shoppingCartItem.create_date,
                save_for_later: shoppingCartItem.save_for_later ?? false,
                select_vendor: shoppingCartItem.select_vendor ?? true,
                unit_or_case: shoppingCartItem.unit_or_case ?? 'unit',
                order_quantity: shoppingCartItem.order_quantity ?? 1
              } as ShoppingCartItemType
            }
            return null
          })
          .filter((item) => item !== null) as ShoppingCartItemType[]
        this.shoppingCartItemsByAddedDate = matchedShoppingCartItems
      } catch (error) {
        console.error('Failed to fetch shopping cart items:', error)
      }
    },
    initializeBuildCart(itemsWithPrices: ShoppingCartItemWithPricesType[]) {
      const outputMap = new Map<number, VendorCart>()
      itemsWithPrices.forEach((item) => {
        if (item.order_quantity && item.order_quantity > 0) {
          const vendorId = item.vendor_id || 0
          if (!outputMap.get(vendorId)) {
            outputMap.set(vendorId, { cartItems: [], totalCartCost: 0 })
          }
          const vendorCart = outputMap.get(vendorId)
          if (vendorCart) {
            const caseMultiplier = getCaseMultiplier(item)
            item.cart_cost = item.cost * item.order_quantity * caseMultiplier
            vendorCart.cartItems.push(item)
          }
        }
      })

      this.shoppingCart = Object.fromEntries(outputMap)
    },
    initializeOrderCarts(itemsWithPrices: ShoppingCartItemWithPricesType[]) {
      const outputMap = new Map<number, VendorCart>()
      itemsWithPrices.forEach((item) => {
        if (!item.save_for_later && item.order_quantity && item.order_quantity > 0) {
          const vendorId = item.vendor_id || 0
          if (!outputMap.get(vendorId)) {
            outputMap.set(vendorId, { cartItems: [], totalCartCost: 0 })
          }
          const vendorCart = outputMap.get(vendorId)
          if (vendorCart) {
            const caseMultiplier = getCaseMultiplier(item)
            item.cart_cost = item.cost * item.order_quantity * caseMultiplier
            vendorCart.cartItems.push(item)
          }
        }
      })

      this.vendorCarts = Object.fromEntries(outputMap)
    },
    getCartSummaries(itemsWithPrices: ShoppingCartItemWithPricesType[]) {
      const vendorCartMap = new Map<number, VendorCart>()
      const shoppingCartMap = new Map<number, VendorCart>()
      const vendorCartCostMap = {}
      const shoppingCartCostMap = {}
      const saveForLaterHash: ShoppingCartItemWithPricesType[] = []

      itemsWithPrices.forEach((item) => {
        if (item.order_quantity && item.order_quantity > 0) {
          const vendorId = item.vendor_id || 0
          if (!vendorCartMap.get(vendorId) && !item.save_for_later) {
            vendorCartMap.set(vendorId, { cartItems: [], totalCartCost: 0 })
          }
          if (!shoppingCartMap.get(vendorId)) {
            shoppingCartMap.set(vendorId, { cartItems: [], totalCartCost: 0 })
          }
          const vendorCart = vendorCartMap.get(vendorId)
          const shoppingCart = shoppingCartMap.get(vendorId)

          if (vendorCart && !item.save_for_later) {
            const caseMultiplier = getCaseMultiplier(item)
            item.cart_cost = item.cost * item.order_quantity * caseMultiplier
            vendorCart.cartItems.push(item)
            vendorCart.totalCartCost = vendorCart.totalCartCost + (item.cart_cost || 0)
            vendorCartCostMap[vendorId] = (vendorCartCostMap[vendorId] || 0) + item.cart_cost
          }
          if (shoppingCart) {
            const caseMultiplier = getCaseMultiplier(item)
            item.cart_cost = item.cost * item.order_quantity * caseMultiplier
            shoppingCart.cartItems.push(item)
            shoppingCart.totalCartCost = shoppingCart.totalCartCost + (item.cart_cost || 0)
            shoppingCartCostMap[vendorId] = (shoppingCartCostMap[vendorId] || 0) + item.cart_cost
          }
          if (item.save_for_later && item.order_quantity && item.order_quantity > 0) {
            saveForLaterHash.push(item)
          }
        }
      })

      this.vendorCarts = Object.fromEntries(vendorCartMap)
      this.vendorCartCost = vendorCartCostMap
      this.shoppingCart = Object.fromEntries(shoppingCartMap)
      this.shoppingCartCost = shoppingCartCostMap
      this.savedForLater = saveForLaterHash
    },
    updateVendorCart(vendorId: number, selectedItem: ShoppingCartItemWithPricesType) {
      const { vendor_id: oldVendorId, id: itemId } = selectedItem

      const oldVendorCart = this.vendorCarts[oldVendorId]
      if (oldVendorCart) {
        oldVendorCart.cartItems = oldVendorCart.cartItems.filter((item) => item.id !== itemId)
        if (oldVendorCart.cartItems.length === 0) {
          delete this.vendorCarts[oldVendorId]
        }
      }

      const newVendorCart = this.vendorCarts[vendorId] ?? { totalCartCost: 0, cartItems: [] }
      newVendorCart.cartItems.push(selectedItem)
      this.vendorCarts[vendorId] = newVendorCart

      selectedItem.vendor_id = vendorId
    },
    resetShoppingCartStore() {
      this.$patch((state) => {
        Object.assign(state, getDefaultState())
      })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShoppingCartStore, import.meta.hot))
}
