<template>
  <VueDatePicker
    v-model="internalValue"
    :month-change-on-scroll="false"
    :time-picker-inline="showTimeSelection"
    :enable-time-picker="showTimeSelection"
    :is-24="false"
    :am-pm-buttom="showTimeSelection"
    :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
    :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
    auto-apply
    ref="datepicker"
    placeholder="Select a date"
    :format="format"
    range
  >
    <template #arrow-left>
      <i class="fa-solid fa-chevron-left fa-sm"></i>
    </template>
    <template #arrow-right>
      <i class="fa-solid fa-chevron-right fa-sm"></i>
    </template>
    <template #input-icon>
      <i class="fa-solid fa-calendar pl-2 pr-2"></i>
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'Range Date Picker Component',
  props: {
    showTimeSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    modelValue: {
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue)

    watch(internalValue, (newValue) => {
      emit('update:modelValue', newValue)
    })

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue
      }
    )

    const format = (dates: Date[]) => {
      const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }
      const date1 = dates[0].toLocaleDateString('en-US', dateOptions)
      const date2 = dates[1].toLocaleDateString('en-US', dateOptions)
      return date1 + ' to ' + date2
    }

    return {
      internalValue,
      format
    }
  }
})
</script>
