<template>
  <div />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useVenuePosStore } from '@/stores/venue-pos.js'
import { mapActions } from 'pinia'

export default defineComponent({
  async created() {
    const connection = await this.getShift4Connection(this.$route.query)
    const connectionStatus =
      connection.status === 'success' ? 'connection=true&status=success' : 'status=failure'
    this.$router.push(`/sales/settings/connect-pos?${connectionStatus}&connection=${connection}`)
  },
  methods: {
    ...mapActions(useVenuePosStore, ['getShift4Connection'])
  }
})
</script>
