<template>
  <Select
    v-model="computedModelValue"
    :options="items"
    optionLabel="name"
    :placeholder="placeholder"
    :checkmark="showCheckmark"
    class="w-full md:w-56 z-100"
    @change="selectItem"
  />
</template>

<script lang="ts">
import { defineComponent, ref, type PropType, watch, computed } from 'vue'
import Select from 'primevue/select'
import type { DropdownItem } from '@/types/dropdown.type'

export default defineComponent({
  name: 'DropdownComponentNew',
  components: {
    Select
  },
  props: {
    placeholder: {
      type: String,
      required: false
    },
    showCheckmark: Boolean,
    items: {
      type: Array as PropType<DropdownItem[]>,
      required: true
    },
    modelValue: {
      default: null,
      required: false
    },
    actionMenu: {
      default: false,
      required: false
    },
    formField: {
      default: false,
      required: false
    }
  },
  emits: ['update:modelValue', 'change', 'select'],
  setup(props, { emit }) {
    const internalModelValue = ref(props.modelValue)

    watch(
      () => props.modelValue,
      (newVal) => {
        if (!props.actionMenu) internalModelValue.value = newVal
      }
    )

    const computedModelValue = computed({
      get() {
        return internalModelValue.value
      },
      set(value) {
        if (!props.actionMenu) internalModelValue.value = value
        emit('update:modelValue', value)
      }
    })

    function selectItem(item: any) {
      emit('update:modelValue', item.value)
      emit('change', item.value)
      emit('select', item.value)
    }

    return {
      computedModelValue,
      internalModelValue,
      selectItem
    }
  }
})
</script>
