<template>
  <div />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useVenuePosStore } from '@/stores/venue-pos.js'
import { mapActions } from 'pinia'

export default defineComponent({
  async created() {
    const encodedConnection = await this.getCloverConnection(this.$route.query)
    this.$router.push(`/sales/settings/connect-pos?connection=${encodedConnection}`)
  },
  methods: {
    ...mapActions(useVenuePosStore, ['getCloverConnection'])
  }
})
</script>
