import type { App } from 'vue'

export default {
  install: (app: App) => {
    app.config.globalProperties.$filters = {
      loadTypeFilter() {
        const typeFilter = sessionStorage.getItem('typeFilter') || JSON.stringify({})
        return Object.assign({ 1: false, 2: false, 3: false, 4: false }, JSON.parse(typeFilter))
      },
      loadSubTypeFilter() {
        const subTypeFilter = sessionStorage.getItem('subTypeFilter') || JSON.stringify({})
        return JSON.parse(subTypeFilter)
      },
      loadStatusFilter() {
        const statusFilter = sessionStorage.getItem('statusFilter') || JSON.stringify({})
        return Object.assign(
          { in_stock: false, below_par: false, out_of_stock: false },
          JSON.parse(statusFilter)
        )
      },
      loadTagFilter() {
        const tagFilter = sessionStorage.getItem('tagFilter') || JSON.stringify({})
        return JSON.parse(tagFilter)
      },
      loadVendorFilter() {
        const vendorFilter = sessionStorage.getItem('vendorFilter') || JSON.stringify({})
        return JSON.parse(vendorFilter)
      }
    }
  }
}
