<template>
  <div class="dual-calendar">
    <div class="d-inline-flex dual-calendar-input">
      <VueDatePicker
        v-model="internalStartDate"
        :month-change-on-scroll="false"
        :time-picker-inline="showTimeSelection"
        :enable-time-picker="showTimeSelection"
        :is-24="false"
        :am-pm-button="showTimeSelection"
        :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
        :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
        auto-apply
        ref="datepicker"
        placeholder="Select a date"
        :format="format"
        :clearable="false"
        :highlight="[internalEndDate]"
        :min-date="minStartDate"
        position="left"
      >
        <template #arrow-left>
          <i class="fa-solid fa-chevron-left fa-sm"></i>
        </template>
        <template #arrow-right>
          <i class="fa-solid fa-chevron-right fa-sm"></i>
        </template>
        <template
          #dp-input="{
            value,
            onInput,
            onEnter,
            onTab,
            onClear,
            onBlur,
            onKeypress,
            onPaste,
            isMenuOpen
          }"
        >
          <div :class="['calendar-group left', isMenuOpen ? 'active' : '']">
            <i class="fas fa-calendar left-icon"></i>
            <input
              type="text"
              :value="value"
              class="startDate"
              placeholder="Select a date"
              readonly
            />
            <i class="fas fa-sort-down right-icon"></i>
          </div>
        </template>
      </VueDatePicker>
      <div class="divider"></div>
      <VueDatePicker
        v-model="internalEndDate"
        :month-change-on-scroll="false"
        :time-picker-inline="showTimeSelection"
        :enable-time-picker="showTimeSelection"
        :is-24="false"
        :am-pm-button="showTimeSelection"
        :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
        :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
        auto-apply
        ref="datepicker"
        placeholder="Select a date"
        :format="format"
        :min-date="minEndDate"
        :clearable="false"
        :highlight="[internalStartDate]"
        position="right"
      >
        <template #arrow-left>
          <i class="fa-solid fa-chevron-left fa-sm"></i>
        </template>
        <template #arrow-right>
          <i class="fa-solid fa-chevron-right fa-sm"></i>
        </template>
        <template
          #dp-input="{
            value,
            onInput,
            onEnter,
            onTab,
            onClear,
            onBlur,
            onKeypress,
            onPaste,
            isMenuOpen
          }"
        >
          <div :class="['calendar-group right', isMenuOpen ? 'active' : '']">
            <input type="text" :value="value" class="endDate" placeholder="Select a date" />
            <i class="fas fa-sort-down right-icon"></i>
          </div>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'Dual Date Picker Component',
  props: {
    showTimeSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    startDate: {
      required: true
    },
    endDate: {
      required: true
    },
    minStartDate: {
      type: [Date, String, Number],
      required: false
    },
    dateOptions: {
      required: false,
      default: { year: 'numeric', month: 'short', day: 'numeric' }
    }
  },
  emits: ['update:startDate', 'update:endDate'],
  setup(props, { emit }) {
    const internalStartDate = ref(props.startDate ? new Date(props.startDate * 1000) : null)
    const internalEndDate = ref(props.endDate ? new Date(props.endDate * 1000) : null)

    watch(internalStartDate, (newValue) => {
      if (newValue) {
        emit('update:startDate', Math.floor(newValue.getTime() / 1000))
      } else {
        emit('update:startDate', null)
      }
    })

    watch(internalEndDate, (newValue) => {
      if (newValue) {
        emit('update:endDate', Math.floor(newValue.getTime() / 1000))
      } else {
        emit('update:endDate', null)
      }
    })

    watch(
      () => props.startDate,
      (newValue) => {
        internalStartDate.value = newValue ? new Date(newValue * 1000) : null
      }
    )

    watch(
      () => props.endDate,
      (newValue) => {
        internalEndDate.value = newValue ? new Date(newValue * 1000) : null
      }
    )

    const format = (date: Date | null) => {
      if (!date) return ''
      return date.toLocaleDateString('en-US', props.dateOptions)
    }

    const minEndDate = computed(() => {
      return internalStartDate.value
    })

    return {
      internalStartDate,
      internalEndDate,
      format,
      minEndDate
    }
  }
})
</script>

<style scoped>
.dual-calendar {
  border-color: #dbdfdf;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75);
  display: flex;
  align-items: center;
  width: 100%;
}

.dual-calendar-input {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
}

.calendar-group {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1; /* Ensure it is above the pseudo-elements */
}

.calendar-group.left::before,
.calendar-group.right::before {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #7d8888;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1; /* Ensure the pseudo-elements are below the actual elements */
}

.calendar-group.left:hover::before,
.calendar-group.right:hover::before,
.calendar-group.left.active::before,
.calendar-group.right.active::before {
  opacity: 1;
}

.left-icon,
.right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.left-icon {
  left: 10px;
}

.right-icon {
  right: 10px;
  top: 15px;
}

input.startDate,
input.endDate {
  border: none !important;
  border-radius: 3px;
  border-color: transparent !important;
  width: 80%;
  padding: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.startDate {
  padding-left: 30px; /* Space for the left icon */
}

input.endDate {
  padding-left: 10px;
  padding-right: 30px; /* Space for the right icon */
}

input.startDate:hover,
input.endDate:hover {
  border: none;
}

input.startDate:focus-visible,
input.endDate:focus-visible {
  outline: none;
}

.divider {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 2px;
  background-color: #dbdfdf;
}
</style>
