import { acceptHMRUpdate, defineStore } from 'pinia'
import $ from 'jquery'

interface State {
  states: any[]
}
export const useStatesStore = defineStore('state', {
  state: (): State => {
    return {
      states: []
    }
  },
  actions: {
    async getStates() {
      this.states = await $.get('/states')
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatesStore, import.meta.hot))
}
