import { acceptHMRUpdate, defineStore } from 'pinia'
import type {
  ItemType,
  ShoppingCartItemType,
  ShoppingCartItemWithPricesType
} from '@/types/item.type.js'

interface State {
  items: ItemType[]
  itemsForInventory: ItemType[]
  itemsWithPrices: ShoppingCartItemWithPricesType[]
  editItemId: number
  newItem: {
    type: string
    typeId: number
    vipProductId?: string
  }
}

export const useItemsStore = defineStore('items', {
  state: (): State => {
    return {
      items: [], // items list returned by getItems
      itemsForInventory: [],
      itemsWithPrices: [], // items with prices
      editItemId: 0,
      newItem: {
        type: '',
        typeId: 0,
        vipProductId: undefined
      }
    }
  },
  actions: {
    async getItems(query: Record<string, any> = {}) {
      const items: ItemType[] = await $.get('/items', query)
      this.items = items
    },
    async getItemsForInventory(query: Record<string, any> = {}) {
      const items: ItemType[] = await $.get('/items/items-for-inventory', query)
      this.itemsForInventory = items
    },
    setEditItemId(id: number) {
      this.editItemId = id
    },
    async getItemPrices(cartItems: ShoppingCartItemType[]) {
      const itemsWithPrices: ShoppingCartItemWithPricesType[] = await $.post('/items/prices', {
        items: cartItems
      })
      this.itemsWithPrices = itemsWithPrices
    },
    setNewItemTypeId(typeId: number) {
      this.newItem.typeId = typeId
    },
    setNewItemVipProductId(vipProductId: string) {
      this.newItem.vipProductId = vipProductId
    },
    setNewItemType(type: string) {
      this.newItem.type = type
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useItemsStore, import.meta.hot))
}
