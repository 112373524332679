import { acceptHMRUpdate, defineStore } from 'pinia'

interface State {
  didbuyAuditLog: any[]
}

export const useDidbuyAuditLogStore = defineStore('didbuyAuditLog', {
  state: (): State => {
    return {
      didbuyAuditLog: []
    }
  },
  actions: {
    async getDidbuyAuditLog() {
      this.didbuyAuditLog = await $.get('/didbuys/audit-log', { excludeSeen: 1 })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDidbuyAuditLogStore, import.meta.hot))
}
