<template>
  <CardComponent :class="[ButtonCardStyle.Filter, isSelected ? 'selected' : '']">
    <template v-slot>
      <div class="d-flex flex-row align-items-start">
        <div class="icon-container">
          <span class="fa-stack small bolt-stack" v-if="showOtcIcon">
            <i class="fa-solid fa-circle fa-stack-2x"></i>
            <i class="fa-solid fa-bolt fa-stack-1x fa-inverse"></i>
          </span>
        </div>
        <p class="card-name" :title="cardName">{{ cardName }}</p>
      </div>
    </template>
  </CardComponent>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import CardComponent from '../CardComponent.vue'
import TagsComponent from '../../TagsComponent.vue'
import { ButtonCardStyle, Icons, TagStyle, TagSize } from '@/enums/components.enum'
import '@styles/cards.styl'

export default defineComponent({
  name: 'Button Card - Filter component',
  components: { CardComponent, TagsComponent },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    showOtcIcon: {
      type: Boolean,
      default: false
    },
    cardName: {
      type: String,
      default: null,
      required: true
    },
    icon: {
      type: String as PropType<Icons>,
      default: null,
      required: false
    }
  },
  setup() {
    return {
      Icons,
      ButtonCardStyle,
      TagStyle,
      TagSize
    }
  }
})
</script>

<style>
.icon-container {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.card-component-wrapper {
  box-shadow: 0px 1px 1px rgba(219, 223, 223, 0.75);
  position: relative;
  background-color: #ffffff;
  transition:
    border 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.card-component-wrapper .card-name {
  font-weight: 600;
  line-height: 16px;
  margin-left: 3px;
}

.bolt-stack {
  font-size: 6px;
}
</style>
