import PrimeVue from 'primevue/config'
import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export const BackbarStylingPreset = definePreset(Aura, {
  primitive: {
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: 'var(--border-radius-small)',
      md: 'var(--border-radius)',
      lg: '8px',
      xl: '12px'
    }
  },
  semantic: {},
  components: {
    button: {
      secondary: {
        focus: {
          ring: {
            color: '#774ee2'
          }
        },
        active: {
          border: {
            color: '#3ae333'
          }
        }
      },
      text: {
        secondary: {
          hover: {
            background: '#AE99AE'
          }
        }
      },
      icon: {
        only: {
          background: '#FFFFFF',
          width: '24px'
        }
      }
    },
    dialog: {
      header: {
        padding: 'var(--padding-lg)'
      },
      footer: {
        padding: 'var(--padding-lg)'
      },
      content: {
        padding: 'var(--padding-lg)'
      },
      border: {
        radius: 'var(--modal-radius)'
      },
      shadow: '0px 4px 24px 0px rgba(12, 28, 25, 0.16)',
      title: {
        font: {
          size: '16px',
          weight: '700'
        }
      }
    }
  }
})
