<template lang="pug">
div(v-if="alerts" class="alert-container")
	transition-group(name="fade")
		div(v-for="(alert, i) in alerts" :key="i" :class="['alert', 'alert-dismissable', alert.class || '']")
			a(aria-label="close" class="close" data-dismiss="alert" href="#")
				| &times;
			strong
				| {{ alert.title }}
			span(v-html="alert.content")
</template>
<script lang="ts">
import { useAlertsStore } from '@/stores/alerts.js'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    ...mapState(useAlertsStore, ['alerts'])
  }
})
</script>
