<template lang="pug">
#wrapper
  noscript
    iframe(src='https://www.googletagmanager.com/ns.html?id=GTM-TVW4RG4' height='0' width='0' style='display:none;visibility:hidden')
  .ie-warning-bar(v-bind:class="{ 'ie-warning-bar-show': IEWebBrowser }")
    | Backbar does not currently support the Internet Explorer web browser. Please download and use another browser such as
    a(href='https://www.google.com/chrome/') Google Chrome.
  slot
</template>
<script lang="ts">
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/animate.css'
import '@/assets/css/styles.styl'
import '@/assets/css/signup.styl'
import 'bootstrap/dist/js/bootstrap.js'
import '@/js/popper-setup.js'
import { defineComponent } from 'vue'

export default defineComponent({
  head() {
    return {
      title: this.$route?.meta?.title || 'Backbar'
    }
  },
  async created() {
    document.body.classList.add('gray-bg')
  }
})
</script>
