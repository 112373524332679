<template>
  <VueDatePicker
    v-model="internalValue"
    :month-change-on-scroll="false"
    :time-picker-inline="showTimeSelection"
    :enable-time-picker="showTimeSelection"
    :is-24="false"
    :am-pm-buttom="showTimeSelection"
    :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
    :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
    auto-apply
    ref="datepicker"
    placeholder="Select a date"
    :format="format"
  >
    <template #arrow-left>
      <i class="fa-solid fa-chevron-left fa-sm"></i>
    </template>
    <template #arrow-right>
      <i class="fa-solid fa-chevron-right fa-sm"></i>
    </template>
    <template #input-icon>
      <i class="fa-solid fa-calendar pl-2 pr-2"></i>
    </template>
  </VueDatePicker>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'Date Picker Component',
  props: {
    showTimeSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    modelValue: {
      required: true
    },
    dateOptions: {
      required: false,
      default: { year: 'numeric', month: 'short', day: 'numeric' }
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue ? new Date(props.modelValue * 1000) : null)

    watch(internalValue, (newValue) => {
      if (newValue) {
        emit('update:modelValue', Math.floor(newValue.getTime() / 1000))
      } else {
        emit('update:modelValue', null)
      }
    })

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue ? new Date(newValue * 1000) : null
      }
    )

    const format = (date: Date | null) => {
      if (!date) return ''
      return date.toLocaleDateString('en-US', props.dateOptions)
    }

    return {
      internalValue,
      format
    }
  }
})
</script>
<style>
.dp__month_year_select {
  font-weight: 700;
}

.dp--header-wrap {
  border-bottom: 1px #ccd1d0 solid;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__input {
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75);
}

.dp__today {
  border-radius: 3px;
}

.dp__arrow_top {
  display: none;
}

.dp__menu {
  border: 1px #ccd1d0 solid;
  box-shadow: 0px 2px 4px 0px rgba(219, 223, 223, 0.75);
  border-radius: 3px;
}
</style>
