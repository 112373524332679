<template>
  <ul :class="['nav navbar-top-links navbar-right']">
    <li>
      <span>
        <a
          :class="['p-0 nav-icon-button']"
          href="https://www.getbackbar.com/support"
          target="_blank"
        >
          <i class="fas fa-question-circle nav-icon"></i>
        </a>
      </span>
    </li>
    <li>
      <span>
        <bb-notifications :class="['pointer icon-group']" ref="bb-notifications"></bb-notifications>
      </span>
    </li>
    <li>
      <span>
        <i class="fas fa-user-circle dropdown-toggle pointer nav-icon" data-toggle="dropdown"></i>
        <!-- @TODO: update DropdownComponent to handle the needs here -->
        <div class="dropdown-menu" aria-labelledby="user-menu-link">
          <a class="dropdown-item" v-if="currentVenue && currentVenue.id" href="/account-settings">
            <i class="mr-2 fas fa-fw fa-id-card pointer"></i>
            User profile
          </a>
          <a
            class="dropdown-item"
            v-if="
              (user.permissions && user.permissions.modify_organization) || user.user_level == 9
            "
            href="/organization/manage"
          >
            <i class="mr-2 fas fa-fw fa-users-cog"></i>
            Manage organization
          </a>
          <a
            class="dropdown-item"
            v-if="(user.permissions && user.permissions.modify_permissions) || user.user_level == 9"
            href="/organization/users"
          >
            <i class="mr-2 fas fa-fw fa-users pointer"></i>
            Manage users
          </a>
          <a class="dropdown-item" @click="logout" href="">
            <i class="mr-2 fas fa-fw fa-sign-out pointer"></i>
            Log out
          </a>
        </div>
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import BBNotificationsComponent from '@/components/BBNotificationsComponent.vue'

export default defineComponent({
  components: { 'bb-notifications': BBNotificationsComponent },
  name: 'NavBarVenue Component',
  computed: {
    ...mapState(useUserStore, ['currentVenue', 'user', 'impersonatorId'])
  },
  mounted() {
    this.$refs['bb-notifications'].getFreshData()
  },
  methods: {
    ...mapActions(useUserStore, ['logoutUser']),
    async logout() {
      try {
        await this.logoutUser()
        if (this.impersonatorId) {
          this.$router.go(0)
          this.$router.push({ name: 'dashboard' })
        } else {
          this.$router.push({ name: 'login' })
        }
      } catch (e) {
        console.log('e', e)
      }
    }
  }
})
</script>
<style lang="stylus">
@import '../../assets/css/variables.styl'

.nav-icon
  height: $button-height-small
  width: $button-width-small
  padding: $padding-xs 0 0 $padding-xs;
  border-radius: 3px
  font-size: 1.25rem
  &:hover
    background-color: $action-secondary-hover
  &:active
    color: $icon-color !important
    background-color: $action-secondary-pressed
.nav-icon-button i
  color: $icon-default
.icon-group
  margin-left: 8px
  margin-right: 8px
</style>
