<template>
  <vue-tags-input
    v-model="inputTag"
    :autocomplete-items="filteredTags"
    :autocomplete-min-length="0"
    :invalid-pattern-text="'Only numbers and letters are allowed'"
    :maxTags="1"
    :maxLength="15"
    :pattern="'^[A-Za-z0-9][A-Za-z0-9 ]*[A-Za-z0-9]$'"
    :tags="tags"
    :placeholder="placeholder"
    @tags-changed="onChangeTags"
  >
  </vue-tags-input>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      placeholder: '',
      inputTag: ''
    }
  },
  props: {
    allTags: Array<string>,
    tags: Array
  },
  methods: {
    updateTagsInput(hideElements: boolean) {
      const thatVm = this
      const inputEl = document.getElementsByClassName('ti-new-tag-input-wrapper')[0] as HTMLElement
      const autoCompleteEl = document.getElementsByClassName('ti-autocomplete')[0] as HTMLElement
      if (inputEl) {
        if (hideElements) {
          inputEl.style.display = 'none'
        } else {
          inputEl.style.display = 'flex'
        }
      }
      if (autoCompleteEl) {
        if (hideElements) {
          autoCompleteEl.style.display = 'none'
        } else {
          autoCompleteEl.style.display = 'block'
        }
      }
    },
    onChangeTags(tags) {
      const thatVm = this
      this.$emit('tagsChange', tags)
      if (tags.length > 0) {
        this.updateTagsInput(true)
      } else {
        this.updateTagsInput(false)
        this.placeholder = 'Type to add'
      }
    }
  },
  mounted() {
    if (this.tags?.length === 0) {
      this.placeholder = 'Type to add'
    }
  },
  computed: {
    filteredTags() {
      const thatVm = this
      return thatVm.allTags
        .map((tag) => {
          return {
            text: tag
          }
        })
        .filter((i) => {
          return i.text.toLowerCase().indexOf(thatVm.inputTag.toLowerCase()) !== -1
        })
    }
  }
})
</script>
