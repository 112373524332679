export enum UserPermissionsEnum {
  AccessInventoryReports = 'access_inventory_reports',
  AccessOrderReports = 'access_order_reports',
  AccessSalesReports = 'access_sales_reports',
  AddToShoppingCart = 'add_to_shopping_cart',
  CrudItems = 'crud_items',
  ManageOrders = 'manage_orders',
  ManagePos = 'manage_pos',
  ManageVendors = 'manage_vendors',
  ModifyBilling = 'modify_billing',
  ModifyMenuPreferences = 'modify_menu_preferences',
  ModifyOrganization = 'modify_organization',
  PlaceOrders = 'place_orders',
  StartEndInventory = 'start_end_inventory',
  TakeInventory = 'take_inventory'
}
