import { useUserStore } from '@/stores/user.js'
import { useVenuePosStore } from '@/stores/venue-pos.js'

export default async function venuePos({ next }) {
  try {
    const userStore = useUserStore()
    if (!userStore.currentVenue) {
      return next({ name: 'login' })
    }
    const { id = null } = userStore.currentVenue
    if (id) {
      return next()
    }
    return next({ name: 'dashboard' })
  } catch (e) {
    return next({ name: 'dashboard' })
  }
}
