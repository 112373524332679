<template>
  <div class="corner-tag-wrapper">
    <div style="justify-content: center; align-items: center; gap: 2px; display: flex">
      <div style="justify-content: center; align-items: center; gap: 8px; display: flex">
        <div
          style="
            text-align: center;
            color: white;
            font-size: 12px;
            font-family: Font Awesome 6 Free;
            font-weight: 900;
            line-height: 16px;
            word-wrap: break-word;
          "
        >
          {{ tagLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Corner tag',
  props: {
    tagLabel: {
      type: String,
      default: () => null
    }
  }
})
</script>

<style>
.corner-tag-wrapper {
  position: absolute; /* Absolute positioning relative to the parent */
  top: -1px; /* Align to the top and adjust for border*/
  right: -1px; /* Align to the right and adjust for border */
  padding-left: 4px;
  padding-right: 4px;
  background: #326f65;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}
</style>
