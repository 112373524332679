import { acceptHMRUpdate, defineStore } from 'pinia'
import type { VenuePosType } from '@/types/venue-pos.type.js'

interface State {
  venuePos: VenuePosType
  connection: Record<string, any>
}

export const useVenuePosStore = defineStore('venuePos', {
  state: (): State => {
    return {
      venuePos: {} as VenuePosType,
      connection: {}
    }
  },
  actions: {
    async getVenuePos() {
      const [venuePos = {}] = await $.get('/venue-pos')
      this.venuePos = venuePos
    },
    async getSquareConnection(payload: any) {
      const connection = await $.post('/pos-auth/square-callback', payload)
      this.connection = connection
      return connection
    },
    async getCloverConnection(payload: any) {
      const connection = await $.get('/pos-auth/clover-callback', payload)
      this.connection = connection
      return connection
    },
    async getShift4Connection(payload: any) {
      const connection = await $.post('/pos-auth/shift4-callback', payload)
      this.connection = connection
      return connection
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVenuePosStore, import.meta.hot))
}
