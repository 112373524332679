import { defineStore, acceptHMRUpdate } from 'pinia'
import $ from 'jquery'

export interface FeatureFlags {
  showCalendarInDashboard?: boolean
  otcPricing?: boolean
  otcInvoices?: boolean
  otcInvoiceHistory?: boolean
  otcOrders?: boolean
  otcVendorManagement?: boolean
  otcLowStockAlerts?: boolean
  productConnect?: boolean
  backbarRedesign?: boolean
}

interface State {
  featureFlags: FeatureFlags
}

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: (): State => {
    return {
      featureFlags: {}
    }
  },
  actions: {
    async getFeatureFlags() {
      const data = (await $.get('/feature-flags')) as FeatureFlags
      this.featureFlags = data
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFeatureFlagsStore, import.meta.hot))
}
