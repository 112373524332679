<template>
  <div :class="['nav navbar navbar-static-top white-bg']">
    <ButtonComponent
      :buttonType="ButtonTypes.Secondary"
      small
      class="navbar-hamburger navbar-minimalize minimalize-styl-2"
    >
      <i class="fa-solid fa-bars"></i>
    </ButtonComponent>
    <NavBarVenueComponent></NavBarVenueComponent>
    <NavBarIconMenuComponent></NavBarIconMenuComponent>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ButtonTypes } from '@/enums/components.enum'
import NavBarVenueComponent from '@/components/NavBar/NavBarVenueComponent.vue'
import NavBarIconMenuComponent from '@/components/NavBar/NavBarIconMenuComponent.vue'

export default defineComponent({
  components: { NavBarVenueComponent, NavBarIconMenuComponent },
  name: 'NavBar Component',
  props: {
    showMenu: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    return {
      ButtonTypes: ButtonTypes
    }
  }
})
</script>

<style lang="stylus">
@import '../../assets/css/variables.styl'

.navbar-hamburger
    width: 30px
    height: $button-height-small
    display: flex
    justify-content: center
    align-items: center
</style>
