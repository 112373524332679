<template>
  <div :class="['row mb-2 mx-0 no-gutters', contentAlign || '']">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RowContentAlignment } from '@/enums/components.enum'

export default defineComponent({
  name: 'BannerComponent',
  props: {
    contentAlign: {
      type: String,
      default: RowContentAlignment.Default,
      validator: (value: RowContentAlignment) => Object.values(RowContentAlignment).includes(value)
    }
  }
})
</script>
