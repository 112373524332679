import { defineStore } from 'pinia'

export enum Pages {
  Build = 'build',
  InventoryItems = 'inventory_items'
}

export class ItemsFilter {
  private pageKey: Pages
  private filter: Record<string, any>

  constructor(page: Pages) {
    this.pageKey = page
    if (!sessionStorage.getItem(page)) {
      sessionStorage.setItem(page, '{}')
    }
    this.filter = JSON.parse(sessionStorage.getItem(page) || '{}')
  }

  private saveFilters() {
    sessionStorage.setItem(this.pageKey, JSON.stringify(this.filter))
  }

  loadFilter(key: string, defaultStructure: any) {
    if (!this.filter[key]) {
      this.filter[key] = defaultStructure
    }
    return this.filter[key]
  }

  setFilter(key: string, value: any) {
    this.filter[key] = value
    this.saveFilters()
  }

  reset() {
    const defaultFilterValues = {
      searchQuery: '',
      typeFilter: { 1: false, 2: false, 3: false, 4: false },
      subTypeFilter: {},
      statusFilter: {
        in_stock: false,
        below_par: false,
        out_of_stock: false
      },
      tagFilter: {},
      vendorFilter: {}
    }

    for (const key in defaultFilterValues) {
      this.setFilter(key, defaultFilterValues[key])
    }
  }
}

function createItemsFilter(page: Pages) {
  const filterInit = new ItemsFilter(page)

  return defineStore({
    id: `items-filter-${page}`,
    state: () => ({
      searchQuery: filterInit.loadFilter('searchQuery', ''),
      typeFilter: filterInit.loadFilter('typeFilter', { 1: false, 2: false, 3: false, 4: false }),
      subTypeFilter: filterInit.loadFilter('subTypeFilter', {}),
      statusFilter: filterInit.loadFilter('statusFilter', {
        in_stock: false,
        below_par: false,
        out_of_stock: false
      }),
      tagFilter: filterInit.loadFilter('tagFilter', {}),
      vendorFilter: filterInit.loadFilter('vendorFilter', {})
    }),
    actions: {
      setFilter(key: string, payload: any) {
        filterInit.setFilter(key, payload)
      },
      reset() {
        filterInit.reset()
      }
    }
  })
}

export const useBuildFilterStore = createItemsFilter(Pages.Build)
export const useInventoryFilter = createItemsFilter(Pages.InventoryItems)
