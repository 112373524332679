<template>
  <div>
    <transition name="fade">
      <div v-if="!isHidden" class="bb-confirm-container">
        <div class="bb-confirm-title text-center">
          <strong>{{ title }}</strong>
        </div>
        <div class="bb-confirm-body">{{ body }}</div>
        <div class="d-flex justify-content-around mt-3">
          <button v-on:click="confirmConfirmation" class="btn btn-success">
            {{ confirmText }}
          </button>
          <button v-on:click="rejectConfirmation" class="btn btn-danger">{{ cancelText }}</button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      title: '',
      body: '',
      confirmText: '',
      cancelText: '',
      isHidden: true,
      confirmationPromise: null
    }
  },
  methods: {
    getConfirmation(args) {
      console.log('getting confirmation?')
      const thatVm = this
      this.isHidden = false
      this.title = args.title || ''
      this.body = args.body || ''
      this.confirmText = args.confirmText || 'Confirm'
      this.cancelText = args.cancelText || 'Cancel'
      this.confirmationPromise = new Promise((resolve) => {
        thatVm.confirmConfirmation = () => {
          thatVm.isHidden = true
          resolve(true)
        }
        thatVm.rejectConfirmation = () => {
          thatVm.isHidden = true
          resolve(false)
        }
      })
      return this.confirmationPromise
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    confirmConfirmation() {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    rejectConfirmation() {}
  }
})
</script>
