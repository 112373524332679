<template>
  <div v-click-outside="hideNotifications" class="bb-notifications-container relative">
    <div
      v-on:click="toggleNotifications"
      :class="
        unseenNotifications
          ? 'notifications-icon-numbered notifications'
          : 'notifications-icon notifications'
      "
    >
      <i class="fas fa-bell"> </i>
      <div v-if="unseenNotifications" class="notifications-counter inline">
        <div class="d-inline-block relative notifications-number">{{ unseenNotifications }}</div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="notificationsAreVisible" class="bb-notifications-popup">
        <h2 class="m-0 p-2">Notifications</h2>
        <hr class="mx-2 my-1" />
        <ul>
          <li v-for="(notification, key) in visibleNotifications" :key="key" class="d-block">
            <div
              v-if="!notification.seen"
              class="bb-notification-content content-link font-weight-bold"
              v-html="notification.title"
            ></div>
            <div
              v-if="!notification.seen"
              class="bb-notification-content content-link font-weight-bold"
              v-html="notification.content"
            ></div>
            <div
              v-if="notification.seen"
              class="bb-notification-content content-link font-weight-bold"
              v-html="notification.title"
            ></div>
            <div
              v-if="notification.seen"
              class="bb-notification-content content-link"
              v-html="notification.content"
            ></div>
            <div class="bb-notification-time py-2">
              {{ $utils.timeDifference(notification.create_date) }}
            </div>
          </li>
          <li v-if="notifications.length == 0" class="d-block">
            You have no notifications at this time
          </li>
          <li
            v-if="notifications.length > visibleNotifications.length"
            v-on:click="showMore"
            class="d-block text-center"
          >
            <strong>Show more</strong>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      notificationsAreVisible: false,
      notifications: [],
      numberOfVisibleNotifications: 5 // how many notifications should the user see at once?
    }
  },
  methods: {
    getFreshData() {
      const thatVm = this
      $.get('/notifications')
        .done((data) => {
          thatVm.notifications = data
        })
        .fail((xhr) => {
          console.log('failed to get notifications ', xhr)
        })
    },
    revealNotifications() {
      const thatVm = this
      thatVm.notificationsAreVisible = true
      setTimeout(() => {
        if (thatVm.unseenNotifications) {
          $.ajax({
            url: '/notifications/mark-all-as-seen',
            method: 'PUT'
          }).done((data) => {
            thatVm.notifications.forEach((notification) => {
              notification.seen = true
            })
          })
        }
      }, 3000)
    },
    hideNotifications() {
      this.notificationsAreVisible = false
    },
    toggleNotifications() {
      this.notificationsAreVisible = !this.notificationsAreVisible
    },
    showMore() {
      this.numberOfVisibleNotifications += 5
    }
  },
  computed: {
    unseenNotifications() {
      return this.notifications.filter((notification) => {
        return !notification.seen
      }).length
    },
    visibleNotifications() {
      return this.notifications.slice(0, this.numberOfVisibleNotifications)
    }
  }
})
</script>
<style lang="stylus">
@import '../assets/css/variables.styl'

.notifications
  height: $button-height-small;
  width: $button-width-small;
  border-radius: 3px;
  font-size: 1.25rem;
  &:hover
    background-color: $action-secondary-hover
  &:active
    color: $icon-color !important
    background-color: $action-secondary-pressed

.notifications-icon
  padding: $padding-xs 0 0 5px;

.notifications-icon-numbered
  padding: $padding-xs 0 0 2px;
</style>
