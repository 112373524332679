<template lang="pug">
component(:is="$route.meta.layout || 'div'")
  alert-component
  RouterView(:key="$route.fullPath")
  alert-modal(ref="alert-modal")
  bb-confirm(ref="bb-confirm")
  didbuys-item-review(v-if="didbuyAuditLog.length" ref="didbuys-item-review")
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useDidbuyAuditLogStore } from '@/stores/didbuy-audit-log.js'
import { useFeatureFlagsStore } from './stores/feature-flags'

export default defineComponent({
  data() {
    return {
      didbuyAuditLog: [],
      didbuyAuditLogStore: useDidbuyAuditLogStore()
    }
  },
  created() {
    this.didbuyAuditLog = this.didbuyAuditLogStore.didbuyAuditLog
    useFeatureFlagsStore().getFeatureFlags()
  },
  watch: {
    'didbuyAuditLogStore.didbuyAuditLog': {
      deep: true,
      handler(newVal) {
        this.didbuyAuditLog = newVal
      }
    }
  }
})
</script>
