export default {
  beforeMount(el, binding) {
    el.addEventListener('click', (event) => event.stopPropagation())
    document.body.addEventListener('click', binding.value)
  },
  beforeUnmount(el, binding) {
    el.removeEventListener('click', (event) => event.stopPropagation())
    document.body.removeEventListener('click', binding.value)
  }
}
