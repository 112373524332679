<template>
  <div :class="['card-component-wrapper', cardStyle ? cardStyle : CardStyle.Default]">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { CardStyle } from '@/enums/components.enum'
import { defineComponent, type VNode } from 'vue'
import '@styles/cards.styl'

export default defineComponent({
  name: 'Card Component',
  props: {
    cardStyle: {
      type: String,
      default: CardStyle.Default,
      validator: (value: CardStyle): boolean => Object.values(CardStyle).includes(value)
    }
  },
  setup() {
    return {
      CardStyle
    }
  }
})
</script>

<style lang="stylus">
.card-title {
  height: 20px;
  margin-block-end: 0;
  margin-top: 5px;
}

.card-title p {
  font-size: 0.875rem;
  font-weight: 700;
}
.card-component-wrapper.kpi {
  box-shadow: 0px 2px 4px rgba(219, 223, 223, 0.75);
  padding: 8px; /* $padding-sm */
  width: 100%;
}
.card-component-wrapper.kpi .kpi-value {
  font-size: 1.5rem;
  line-height: 2rem;
}
.card-component-wrapper.kpi .kpi-link {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px #CCD1D0 solid;
}

.card-component-wrapper.nested.filter {
  width: 125px;
  text-align: center;
}

.card-component-wrapper.default.filter {
  border: 1px solid #ccd1d0;
  width: 125px;
  text-align: left;
  justify-content: left;
  padding: 12px 8px;
}
</style>
