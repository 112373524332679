import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AlertType } from '@/types/alert.type.js'

interface State {
  alerts: AlertType[]
}

export const useAlertsStore = defineStore('alerts', {
  state: (): State => {
    return {
      alerts: []
    }
  },
  actions: {
    addAlert(newAlert: AlertType) {
      this.alerts.push(newAlert)
      const { timeout = 3000 } = newAlert
      setTimeout(() => {
        this.alerts = this.alerts.filter((alertObj: AlertType) => alertObj.title !== newAlert.title)
      }, timeout)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAlertsStore, import.meta.hot))
}
