import { acceptHMRUpdate, defineStore } from 'pinia'
import $ from 'jquery'

interface State {
  countries: any[]
}
export const useCountriesStore = defineStore('countries', {
  state: (): State => {
    return {
      countries: []
    }
  },
  actions: {
    async getCountries() {
      this.countries = await $.get('/countries')
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCountriesStore, import.meta.hot))
}
