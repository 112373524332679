<template>
  <div />
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useVenuePosStore } from '@/stores/venue-pos.js'
import { mapActions } from 'pinia'

export default defineComponent({
  async created() {
    const connection = await this.getSquareConnection(this.$route.query)
    if (connection.status === 'success') {
      this.$router.push('/sales/settings/connect-pos?connection=true&status=success')
    } else {
      this.$router.push('/sales/settings/connect-pos?status=failure')
    }
  },
  methods: {
    ...mapActions(useVenuePosStore, ['getSquareConnection'])
  }
})
</script>
