import { acceptHMRUpdate, defineStore } from 'pinia'
import type { ItemType, VendorsWithShoppingCartItems } from '@/types/item.type'
import type { OrderType } from '@/types/order.type'

interface State {
  currentOrganization: { name: string; plan: number | null }
  currentVenue: { vip_verification_status: string | null }
  user: { id: number | null; email: string }
  items: ItemType[]
  orders: OrderType[]
  vendorCarts: VendorsWithShoppingCartItems | undefined
  selected_plan: { name: string }
  eACV: number | null
}

export const useGtmStore = defineStore({
  id: 'gtmStore',
  state: (): State => {
    return {
      currentOrganization: {
        name: '',
        plan: null
      },
      currentVenue: {
        vip_verification_status: null
      },
      user: {
        id: null,
        email: ''
      },
      items: [],
      orders: [],
      vendorCarts: undefined,
      selected_plan: {
        name: ''
      },
      eACV: 0
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGtmStore, import.meta.hot))
}
