import { useUserStore } from '@/stores/user.js'

export default async function venue({ next }) {
  try {
    const userStore = useUserStore()
    if (!Object.keys(userStore.currentVenue).length) {
      return next({ name: 'create-organization' })
    }
    return next()
  } catch (e) {
    return next({ name: 'login' })
  }
}
