import type { AlertType } from '@/types/alert.type.js'
import { useAlertsStore } from '@/stores/alerts.js'
import type { App } from 'vue'

export default {
  install: (app: App) => {
    app.config.globalProperties.$alerts = {
      addAlert(alert: AlertType) {
        const alertsStore = useAlertsStore()
        alertsStore.addAlert(alert)
      }
    }
  }
}
