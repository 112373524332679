<template lang="pug">
<div class="modal fade" id="alert-modal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered my-0" role="document">
      <div class="modal-content modal-rounded">
          <div class="modal-body p-2 px-1 order-modal-body text-center">
              <span class="text-muted fa fa-times modal-close" data-dismiss="modal"></span>
              <h3 class="text-center py-2">
                  <i class="fas fa-exclamation-triangle"></i> Alert
              </h3>
              <div class="text h5 py-2">{{alertText}}</div>
              <button class="btn btn-primary mb-2">{{alertButtonText}}</button>
          </div>
      </div>
  </div>
</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      alertText: '',
      alertButtonText: ''
    }
  }
})
</script>
