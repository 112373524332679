<template lang="pug">
div(class="footer")
	div(class="pull-right")
	div
		| Backbar &copy; {{ currentYear }}
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
})
</script>
